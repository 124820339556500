* {
  box-sizing: border-box;
}

input {
  box-sizing: border-box;
}

iframe {
  width: 100%;
  height: 100%;
}

.como-bold {
  font-family: como, sans-serif;
  font-weight: 600;
  font-style: normal;
}

.jc-ac {
  display: flex;
  justify-content: center;
  align-items: center;
}

.jb-ac {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.jb-as {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.ja-ac {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.je-ac {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.js-ac {
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.js-as {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
}

.jb-as {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.jc-as {
  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.flex-col {
  flex-direction: column;
}

.input {
  width: 100%;
  height: 44px;
  border: 2px solid #5c7d6d;
  border-radius: 4px;
  background: transparent;
  padding-left: 12px;
  margin-bottom: 16px;
  color: #5c7d6d;
  font-weight: 600;
  outline: none!important
}

.auth-input::placeholder {
  color: #5c7d6d;
  opacity: .5
}

.btn1 {
  width: 100%;
  height: 40px;
  /* border-radius: 2px; */
  background: #5c7d6d;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  font-family: como, sans-serif;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  border: 1.5px solid rgba(0,0,0,0);
  line-height: 1;
  padding-top: 2px;
}

.btn1:hover {
  border: 1.5px solid #5c7d6d;
  background: #19181a;
  color: #5c7d6d;
  text-shadow: 1px 1px 1px black
}

.btn2 {
  width: 100%;
  height: 40px;
  /* border-radius: 3px; */
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  font-family: como, sans-serif;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  border: 1.5px solid rgba(0,0,0,0);
}

.btn2:hover {
  border: 1.5px solid red;
  background: rgba(0,0,0,0);
  background: #19181a;
  color: red;
  text-shadow: 1px 1px 1px black
}

.btn3 {
  width: 100%;
  height: 40px;
  /* border-radius: 3px; */
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  font-family: como, sans-serif;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  border: 1.5px solid #5c7d6d;
}

.btn3:hover {
  background: #19181a;
  text-shadow: 1px 1px 1px black
}

.btn-danger {
  width: 100%;
  height: 40px;
  /* border-radius: 3px; */
  background: red;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  color: white;
  text-transform: uppercase;
  font-family: como, sans-serif;
  font-weight: 700;
  font-style: normal;
  cursor: pointer;
  border: 1.5px solid rgba(0,0,0,0);
}

.btn-danger:hover {
  border: 1.5px solid red;
  background: #19181a;
  color: red;
  text-shadow: 1px 1px 1px black
}

.text-hover {
  cursor: pointer
}

.text-hover:hover {
  text-decoration: underline;
}

.grid-btn {
  left: 14px;
  bottom: 14px;
  /* border-radius: 6px; */
  height: 32px;
  width: 52px;
  box-shadow: 2px 2px 12px rgba(0,0,0,.12);
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  font-size: 10px;
  font-weight: 700;
  color: rgb(0,0,0);
  transition: all 200ms linear;
  background: rgb(230, 230, 230);
  box-shadow: inset 1px 1px 3px rgba(220, 220, 220), inset -1px -1px 3px rgba(250, 250, 250);
  cursor: pointer;
  margin-right: 6px;
}

.grid-btn:hover {
  color: rgb(255,255,255);
  font-weight: 800;
  /* box-shadow: inset 1px 1px 3px rgb(92, 125, 109), inset -1px -1px 3px rgb(92, 125, 109); */
  background: rgb(92, 125, 109)
}

.latlng-wrap {
  position: absolute;
  left: 50%;
  bottom: 14px;
  border-radius: 10px;
  background: white;
  height: 44px;
  box-shadow: 2px 2px 12px rgba(0,0,0,.22);
  padding: 6px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  transform: translate(-50%, 0%);
}

.latlng-input {
  width: 144px;
  height: 100%;
  border: none;
  outline: none;
  font-weight: 500;
  border-radius: 6px;
  background: rgb(230, 230, 230);
  max-width: 100%;
  box-shadow: inset 1px 1px 3px rgba(220, 220, 220), inset -1px -1px 3px rgba(250, 250, 250);
  font-size: 14px;
  display: flex;
  padding-left: 12px;
}

.latlng-input::placeholder {
  color: rgb(140, 140, 140);
  font-weight: 500;
}

.latlng-input:last-of-type {
  margin-left: 6px!important;
  margin-right: 6px!important;
  flex-shrink: 0
}

.latlng-btn {
  width: 80px;
  height: 100%;
  background: #3f6bb4;
  color: white;
  font-size: 14px;
  font-weight: 600;
  justify-content: center;
  align-items: center;
  display: flex;
  cursor: pointer;
  border-radius: 6px;
}

.header-nav-wrap {
  background: #19181a;
  border-radius: 6px;
  padding: 3px;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0), inset -1px -1px 3px rgba(65, 64, 66);
}

.header-nav-btn {
  color: white;
  padding: 7px 20px;
  font-size: 16px;
  /* border-radius: 4px; */
  cursor: pointer;
  text-shadow: 1px 1px 1px rgba(0,0,0,.2)
}

.header-logout-btn {
  background: #19181a;
  border-radius: 6px;
  padding: 3px;
  box-shadow: inset 1px 1px 3px rgba(0, 0, 0), inset -1px -1px 3px rgba(65, 64, 66);
}

.header-logout-btn-inner {
  color: white;
  padding: 4px 12px;
  font-size: 14px;
  border-radius: 3px;
  cursor: pointer;
  text-shadow: 1px 1px 1px rgba(0,0,0,.2);
  background: #5c7d6d;
  box-shadow: 2px 2px 3px rgba(0,0,0,.12), inset 1px 1px 2px rgba(101, 181, 128, 1), inset -1px -1px 2px rgba(41, 121, 68, 1)
}

.header-logout-btn-inner:hover {
  color: 5c7d6d;
  text-shadow: 1px 1px 1px rgba(0,0,0,1);
  background: none;
  box-shadow: none
}

.input-styles {
  width: 360px;
  background: transparent;
  border: 1.5px solid #5c7d6d;
  height: 40px;
  padding-left: 10px;
  border-radius: 1px;
  outline: none;
  color: white;
  margin-top: 14px;
}

.light-grey-bg {
  background: rgba(54,54,54,1)!important
}

.blog-item-hover:hover {
  background: rgba(71, 151, 97, .25)!important
}

.truncate1 {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 1;
           line-clamp: 1;
   -webkit-box-orient: vertical;
}

.truncate {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 2;
           line-clamp: 2;
   -webkit-box-orient: vertical;
}

.truncate3 {
   overflow: hidden;
   text-overflow: ellipsis;
   display: -webkit-box;
   -webkit-line-clamp: 3;
           line-clamp: 3;
   -webkit-box-orient: vertical;
}

.hover-item:hover {
  background: rgba(92, 125, 109, .25)
}
